
import { CenteredContainerLg } from '/components/pageOnly/CenteredContainer'
import { SectionGray } from '/components/home/pageOnly/Section'
import { LinkText } from '/components/buildingBlocks/Link'
import { TextXl } from '/components/buildingBlocks/Text'
import { AnimateChildren } from '/machinery/AnimateChildren'
import { Header } from '/components/pageOnly/Header'
import styles from './PageNotFound.css'

export function PageNotFound() {
  return (
    <SectionGray>
      <CenteredContainerLg>
        <Positioning>
          <Content />
        </Positioning>
      </CenteredContainerLg>
    </SectionGray>
  )
}

function Positioning({ children }) {
  return (
    <div className={styles.componentPositioning}>
      {children}
    </div>
  )
}

function Content() {
  return (
    <div className={styles.componentContent}>
      <Header />
      <Error />
    </div>
  )
}

function Error() {
  return (
    <div className={styles.componentError}>
      <AnimateChildren>
        <TextXl>
          Page not found
        </TextXl>
        <LinkText dataX='link-to-home' to="/">
          Navigate me to the FAQ
        </LinkText>
      </AnimateChildren>
    </div>
  )
}
